import axios from 'axios';
import {fetchAuthSession} from "aws-amplify/auth";




// content type
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.put['Content-Type'] = 'application/json';
//axios.defaults.baseURL = import.meta.env.VITE_PUBLIC_REST_SERVICE_URL;
//axios.defaults.baseURL = import.meta.env.VITE_PUBLIC_PROTECTED_API_URL;
//axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_PUBLIC_PROTECTED_API_URL,
});
axiosInstance.interceptors.request.use(async(config) => {
    config.headers = { 'Content-Type': 'application/json' };

    try{
        const session = await fetchAuthSession({forceRefresh: true})
        //@ts-ignore
        config.headers['Authorization'] = session.tokens.idToken
    }catch(e){

    }

    return config;
});

const axiosMultiInstance = axios.create({
    baseURL: "https://aymjwffthl.execute-api.us-west-2.amazonaws.com/dev/create"
})

axiosMultiInstance.interceptors.request.use((config) => {
    config.headers = { 'Content-Type': 'multipart/form-data' };

    const lsToken = localStorage.getItem("token");
    // console.log('lsToken', lsToken);
    if (lsToken && lsToken !== 'null') {
        const token = JSON.parse(lsToken);
        config.headers['Authorization'] = token.idToken;
    }

    return config;
})

const axiosFileApiInstance = axios.create({
    baseURL: import.meta.env.VITE_PUBLIC_FILE_API_URL
})
axiosFileApiInstance.interceptors.request.use(async (config) => {
    config.headers = { 'Content-Type': 'application/json' };

    try{
        const session = await fetchAuthSession({forceRefresh: true})
        //@ts-ignore
        config.headers['Authorization'] = session.tokens.idToken
    }catch(e){

    }


    return config;

})

// intercepting to capture errors
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        // console.log("beginning of eror clasue")
        // console.log("error: ",error.response)

        if(!error.response || !error){
            //console.log("apiCore error response undefined error: ")
            // localStorage.setItem("user","")
            // localStorage.setItem("token","")
            // localStorage.setItem("refreshToken","")
            //localStorage.setItem("account","")
            //window.location = "/login"
        }

        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            if (error.response.data.message) {
                console.error(error.response.data.message);
                return Promise.reject(error.response.data.message);
            } else {
                window.location.href = '/access-denied';
            }
        } else {
            switch (error?.response?.status) {
                case 401:
                    message = 'Invalid credentials';
                    //localStorage.clear()
                    //console.log("hit 401 axios clause")
                    localStorage.setItem("user","")
                    localStorage.setItem("account","")
                    window.location = "/login"
                    //window.location.reload()
                    break;

                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

// const AUTH_SESSION_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
// const setAuthorization = (token) => {
//     if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
//     else delete axios.defaults.headers.common['Authorization'];
// };
//
// const getUserFromSession = () => {
//     const user = sessionStorage.getItem(AUTH_SESSION_KEY);
//     return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
// };
export class APICore {
    /**
     * Fetches data from given url
     */
    constructor() {
    }
    get = (url, params) => {
        let response;
        if (params) {
            const queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axiosInstance.get(`${url}?${queryString}`, params);
        } else {
            response = axiosInstance.get(`${url}`, params);
        }
        return response;
    };

    // getFile = (url, params) => {
    //     let response;
    //     if (params) {
    //         const queryString = params
    //             ? Object.keys(params)
    //                 .map((key) => key + '=' + params[key])
    //                 .join('&')
    //             : '';
    //         response = axiosInstance.get(`${url}?${queryString}`, {responseType: 'blob'});
    //     } else {
    //         response = axiosInstance.get(`${url}`, {responseType: 'blob'});
    //     }
    //     return response;
    // };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axiosInstance.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url, data) => {
        return axiosInstance.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axiosInstance.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url, data) => {
        return axiosInstance.put(url, data);
    };

    updateWithParams = (url, params) => {
        let response;
        // const config = {
        //     headers:{
        //         ...axios.defaults.headers,
        //     }
        // }
        if (params) {
            response = axios.put(`${url}`, null, {
                params: params,
                headers: {
                    ...axios.defaults.headers
                }
            });
        } else {
            response = axios.put(`${url}`);
        }
        return response;
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        return axiosInstance.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };



    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axiosInstance.patch(url, formData, config);
    };

    //s3 file upload methods

    // uploadFiles = (files, entityKey, entityName, s3Folder, setting) => {
    //
    //     const formData = new FormData();
    //     for(const k in files){
    //         let file = new File([files[k]], files[k].name)
    //         //console.log("file check",file)
    //         formData.append('files',file)
    //         //console.log(formData.get("bulldog.jfif"))
    //     }
    //     formData.append("entityKey",entityKey)
    //     formData.append("s3Folder", s3Folder)
    //     formData.append("entityName", entityName)
    //
    //     // const data = {'files': formData}
    //     //console.log(formData.entries())
    //     const config = {
    //         headers: {
    //             ...axios.defaults.headers,
    //             'content-type': 'multipart/form-data',
    //         },
    //     };
    //     return axios.post(`${process.env.NEXT_PUBLIC_REST_SERVICE_URL}/api/v0/file/upload/${setting}`, formData, config)
    // }

    getAccountFiles = () => {
        return axiosFileApiInstance.get(`/account/files`)
    }

    getFile = (fileKey) => {
        return axiosFileApiInstance.get(`/get?key=${encodeURIComponent(fileKey)}`)
    }







    // setLoggedInUser = (session) => {
    //     if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    //     else {
    //         sessionStorage.removeItem(AUTH_SESSION_KEY);
    //     }
    // };

    /**
     * Returns the logged in user
     */
    // getLoggedInUser = () => {
    //     return getUserFromSession();
    // };

    // setUserInSession = (modifiedUser) => {
    //     let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    //     if (userInfo) {
    //         const { token, user } = JSON.parse(userInfo);
    //         this.setLoggedInUser({ token, ...user, ...modifiedUser });
    //     }
    // };
}

/*
Check if token available in session
*/
// let user = getUserFromSession();
// if (user) {
//     const { token } = user;
//     if (token) {
//         setAuthorization(token);
//     }
// }

// export { APICore, setAuthorization };