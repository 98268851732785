import {useLayoutData} from "../../../context/LayoutContext";
import {Collapse, Dropdown, Fade} from "react-bootstrap";
import {useAuthData} from "../../../context/AuthContext";
import {useRef, useState} from "react";
import QrMonthlyUsage from "./QrMonthlyUsage";
import {useAppData} from "../../../context/AppDataContext";
import HatcheryConnectLogo from "../svg/HatcheryConnectLogo";
import {useNavigate} from "react-router";

export type TopBarProps = {
    pageTitle?:string|undefined
}
function TopBar({pageTitle}:TopBarProps){
    const {toggleMode, layoutConfig, lightMode} = useLayoutData()
    const {user,logout,account} = useAuthData()
    const [dropdownShow, setDropdownShow] = useState<boolean>(false)
    const navigate = useNavigate()

    const customerPortalLink = import.meta.env.VITE_PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK || ""
    if(customerPortalLink === ""){
        console.error("stripe customer portal variable not set correctly")
    }


    return(
        <>
            {(layoutConfig && layoutConfig.includeTopBar) && (
                <div className="c-header">
                    {/*Layer 3 - Button column (left) - header*/}
                    {/*<div id="c-btn-col-header" datatype="menu"*/}
                    {/*     className="c-btn-col-header  c-d-flex justify-content-center align-items-center">*/}

                    {/*</div>*/}
                    <div className="c-btn icon m-2 p-2" style={{width: "300px", height:"auto",}} role={"button"} onClick={() => navigate("/", {replace:true})}>
                        {/*<a href={"/"}>*/}
                        {/*    <img src="https://connect-webapp-assets.s3.us-west-2.amazonaws.com/images/brand/connect_horizontal_color_cropped.png" className="img-fluid"*/}
                        {/*         alt={""}/>*/}
                        {/*</a>*/}
                        <HatcheryConnectLogo/>
                    </div>
                    {/*Layer 3 - Elements column - header*/}
                    {/*{layoutConfig.includeLeftSubMenu && (*/}
                    {/*    <div id="c-elements-header" datatype="menu"*/}
                    {/*         className="c-elements-header   px-3 c-d-flex justify-content-between align-items-center">*/}
                    {/*        <form>*/}
                    {/*            <input*/}
                    {/*                type="text"*/}
                    {/*                className="form-control form-rounded  borderless"*/}
                    {/*                placeholder="Search..."*/}
                    {/*                style={{backgroundColor: "transparent"}}/>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {/*Layer 3 - Main column - header (File Name)*/}
                    <nav id="c-main-header" datatype="menu"
                         className="c-main-header   c-d-flex justify-content-between align-items-center">
                        <h1 className={`p-3 fs-1 fw-semibold text-hd`}>{pageTitle}</h1>
                        <ul className="c-d-flex gap-1 pe-3">
                            <li className="d-flex align-items-center">
                                {/*<button aria-label="Button name" className="icon">*/}
                                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                                {/*         className="bi bi-stack" viewBox="0 0 16 16">*/}
                                {/*        <path*/}
                                {/*            d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>*/}
                                {/*        <path*/}
                                {/*            d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>*/}
                                {/*    </svg>*/}

                                {/*</button>*/}
                                {user && (
                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" >
                                            {user.email}
                                        </Dropdown.Toggle>

                                        <Fade in={dropdownShow}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/">Home</Dropdown.Item>
                                                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                                                <Dropdown.Item href={customerPortalLink}>Subscription</Dropdown.Item>
                                                <Dropdown.Item as={"button"} onClick={() => logout()}>Logout</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Fade>
                                    </Dropdown>
                                )}
                            </li>
                            {/*<li>*/}
                            {/*    <button aria-label="Button name" className="icon">*/}
                            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                            {/*             className="bi bi-stack" viewBox="0 0 16 16">*/}
                            {/*            <path*/}
                            {/*                d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>*/}
                            {/*            <path*/}
                            {/*                d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>*/}
                            {/*        </svg>*/}

                            {/*    </button>*/}
                            {/*</li>*/}
                            <li>
                                <button onClick={() => toggleMode()} aria-label="Button name" className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
                                    </svg>

                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </>
    )
}

export default TopBar