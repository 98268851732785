

export const unprotectedRoutes = [
    "/login",
    "/new-password",
    "/oauth-signin",
    "/password-reset",
    "/signup",
    "/payments/pricing",
    "/payments/confirmation",
    "/logout",
    "/oauth-signin"
]